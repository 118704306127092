<!-- Toast.vue -->
<template>
  <teleport to="#app">

    <transition name="fade">
      <div v-if="visible" class="toast" :class="type" :key="message" ref="toastRef">
        <span v-if="icon" class="icon material-symbols-outlined">{{ icon }}</span>
        <span class="toast-msg">{{ message }}</span>
        <div v-if="type === 'error'" @click="closeMessage" class="modal-close material-symbols-outlined">close</div>
      </div>
    </transition>
  </teleport>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      message: '',
      type: '',
      icon: '',
      duration: 0,
      timer: null,  // change this from a boolean to a timeout ID
    };
  },
  methods: {
    show(params) {
      this.duration = params.duration || 0;
      console.log('ToastNotification.vue params: ', params);
      // clear the old timer (if it exists) when a new toast is shown
      if (this.timer !== null) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      // merge new params into data
      Object.assign(this.$data, this.$options.data.call(this), params);
      this.visible = true;

      // start the timer for click outside to close, if duration is not 0
      if (this.duration !== 0) {
        this.timer = setTimeout(() => {
          this.visible = false;
        }, this.duration);
      } else {
        // start a timer for click outside to close after 3 seconds
        console.log('starting timer..');
        this.timer = setTimeout(() => {
          this.timer = null;
        }, 3000);
      }
    },
    closeMessage() {
      this.visible = false;
    },
    handleClickOutside(event) {
      // clicking outside the toast will close it after 3 seconds
      if (this.$refs.toastRef && !this.$refs.toastRef.contains(event.target) && this.timer === null) {
        this.visible = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    // clear the timer if the component is unmounted
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
};

</script>
<style scoped>
/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
} */
.toast {
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  bottom: 0px;
  margin: 20px;
  background-color: #444;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 99999;
}

.toast.info {
  background-color: #007bff;
}

.toast.warning {
  background-color: #ffc107;
}

.toast.error {
  background-color: #dc3545;
  padding-right: 40px
}

.toast.success {
  background-color: #28a745;
}
.modal-close {
  opacity: .5;
}
@media only screen and (max-width: 767px) {
  .toast {
    position: fixed;
    top: 0px;
    bottom: auto;
    margin: 10px;
    border-radius: 0px;
    min-height: 60px;
  }
}
</style>