import { createStore } from 'vuex';
import global from './modules/global'
import auth from './modules/auth'
import booking from './modules/booking';
import menu from './modules/menu'
import loading from './modules/loading'
import calendar from './modules/calendar';
import treatments from './modules/treatments';

const store = createStore({
  modules: {
    global,
    auth,
    booking,
    calendar,
    menu,
    loading,
    treatments
  },
});

export default store;