export default {
  state() {
    return {
      loading: false,
    };
  },
  mutations: {
    loading(state, payload) {
      state.loading = payload.val;
    },
  },
  actions: {
    async loading({ commit }, val) {
      commit('loading', { val: val })
      return
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
  }
}