import { createApp, inject, createVNode, render } from 'vue';
import CustomConfirmDialog from './CustomConfirmDialog.vue';

export default {
  install(app, options) {
    const confirmDialogOptions = {
      ...{
        containerId: 'confirm-dialog-container',
        message: 'Are you sure?',
        trueButtonText: 'Confirm',
        falseButtonText: 'Cancel'
      },
      ...options
    };

    const confirmDialogContainer = document.createElement('div');
    confirmDialogContainer.id = confirmDialogOptions.containerId;
    document.body.appendChild(confirmDialogContainer);

    const confirmDialogApp = createApp();

    const ConfirmDialogComponent = createVNode(
      CustomConfirmDialog,
      {
        message: confirmDialogOptions.message,
        trueButtonText: confirmDialogOptions.trueButtonText,
        falseButtonText: confirmDialogOptions.falseButtonText
      }
    );

    confirmDialogApp.component('CustomConfirmDialog', ConfirmDialogComponent);
    const confirmDialogRoot = createVNode('div', null, [ConfirmDialogComponent]);
    render(confirmDialogRoot, confirmDialogContainer);

    app.provide('confirm', (result) => {
      // Access the result using `inject` in the component
      const confirmCallback = inject('confirmCallback');
      if (confirmCallback) {
        confirmCallback(result);
      }
    });

    let unmountFunction = null;

    app.config.globalProperties.$confirm = (message, trueButtonText, falseButtonText) => {
      return new Promise((resolve) => {

        // Unmount the previous instance if it exists
        if (unmountFunction) {
          unmountFunction();
        }

        const ConfirmDialogComponent = createVNode(
          CustomConfirmDialog,
          {
            message: message || confirmDialogOptions.message,
            trueButtonText: trueButtonText || confirmDialogOptions.trueButtonText,
            falseButtonText: falseButtonText || confirmDialogOptions.falseButtonText,
            onResult: resolve,  // resolve the promise when the result is emitted
          }
        );

        const confirmDialogRoot = createVNode('div', null, [ConfirmDialogComponent]);

        // Store the unmount function
        unmountFunction = render(confirmDialogRoot, confirmDialogContainer);

        ConfirmDialogComponent.component.proxy.visible = true;
      });
    };



  }
};
