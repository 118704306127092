<template>
  <div v-if="visible" class="confirm-dialog">
    <div class="confirm-dialog-content">
      <p>{{ message }}</p>
      <div class="confirm-dialog-buttons">
        <button class='secondary' @click="cancel">{{falseButtonText}}</button>
        <button @click="confirm">{{trueButtonText}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'CustomConfirmDialog',
  props: [ 'trueButtonText', 'falseButtonText', 'message'],
  setup(props, context) {
    const visible = ref(false);

    const confirm = () => {
      visible.value = false;
      context.emit('result', true);
    };

    const cancel = () => {
      visible.value = false;
      context.emit('result', false);
    };

    return {
      visible,
      confirm,
      cancel
    };
  }
};
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.confirm-dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  max-width: 400px;
}

.confirm-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm-dialog-buttons button {
  margin-left: 10px;
}
</style>
