export default {
  state() {
    return {
      isMobile: null,
      isPWA: false,
      showNavbar: true
    };
  },
  mutations: {
    isMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    isPWA(state, isPWA) {
      state.isPWA = isPWA;
    },
    showNavbar(state, showNavbar) {
      state.showNavbar = showNavbar;
    }
  },
  actions: {
    isMobile({ commit }) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileRegex = /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i;
      const isSmallScreen = window.matchMedia('(max-width: 460px)').matches;
      const isMobile = isMobileRegex.test(userAgent) || isSmallScreen;
      commit('isMobile', isMobile)
    },
    isPWA({ commit }, isPWA) {
      commit('isPWA', isPWA);
    },
    showNavbar({ commit }, showNavbar) {
      commit('showNavbar', showNavbar);
    }
  },
  getters: {
    isMobile(state) {
      return state.isMobile;
    },
    isPWA(state) {
      return state.isPWA;
    },
    showNavbar(state) {
      return state.showNavbar;
    }
  }
}
