import { createVNode, render } from 'vue';
import Toast from '@/components/ui/ToastNotification.vue';

const createToast = (app, options) => {
  const container = document.createElement('div');
  const toast = createVNode(Toast, options);

  const show = (params) => {
    render(toast, container);
    if (!container.isConnected) {
      document.body.appendChild(container);
    }
    toast.component.proxy.show(params);
  }

  return show;
};

export default {
  install: (app, options) => {
    const toastShow = createToast(app, options);

    app.config.globalProperties.$toast = {
      show: toastShow,
    };

    app.config.globalProperties.$toast_error = {
      show: (message, duration) => {
        toastShow({
          message,
          type: "error",
          icon: "error",
          duration: duration || 0,
          key: Date.now(),
        });
      },
    };

    app.config.globalProperties.$toast_warn = {
      show: (message, duration) => {
        toastShow({
          message,
          type: "warning",
          icon: "warning",
          duration: duration || 6000,
          key: Date.now(),
        });
      },
    };

    app.provide('toast', app.config.globalProperties.$toast);
    app.provide('error', app.config.globalProperties.$toast_error);
    app.provide('warn', app.config.globalProperties.$toast_warn);
  },
};
