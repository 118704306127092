import inspiru from '@/api/inspiru';

export default {
  state() {
    return {
      treatments: [],
    }
  },
  mutations: {
    setTreatments(state, treatments) {
      state.treatments = treatments;
    },
  },
  actions: {
    setTreatments(context, treatments) {
      context.commit('setTreatments', treatments);
    },
    async getTreatments(context) {
      if (context.state.treatments.length) {
        console.log('found in store')
        return context.state.treatments
      }
      let treatments = await inspiru.getTreatments();
      console.log('getTreatments: ', treatments)
      context.commit('setTreatments', treatments);
      return treatments
    },
  },
  getters: {
    treatments(state) {
      return state.treatments;
    },
  }
}
