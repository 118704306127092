import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import LoaderAni from '/src/components/ui/LoaderAni.vue'
import App from './App.vue'
const app = createApp(App);

import axios from 'axios';
import store from '@/store/index.js'
import ToastPlugin from '@/plugins/toast';
import './helpers/registerServiceWorker'; // PWA

app.use(ToastPlugin);
app.use(store)



/* use Router */

// Global configuration across all routes
const globalConfig = async () => {
  // store.dispatch('auth/setLocale')
  store.dispatch('showNavbar', false)
  store.dispatch('loading', true)
  store.dispatch('isMobile')

  if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
    store.dispatch('isPWA', true)
  }
}
// Must be logged in
const auth = async () => {
  await store.dispatch('auth/initUser');
  if (!store.getters['auth/isLoggedIn']) {
    return '/login';
  }
};

// If logged in, redirect to calendar page
const checkAuth = async () => {
  await store.dispatch('auth/initUser');
  if (store.getters['auth/isLoggedIn']) {
    return '/calendar';
  }
};

// Must be logged in as an active clinic
const clinicAuth = async () => {
  store.dispatch('showNavbar', true)
  const authResult = await auth();
  if (authResult) {
    return authResult;
  } else if (['clinic_new', 'clinic_pending'].includes(store.getters['auth/userStatus'])) {
    return '/welcome';

  } else if (!store.getters['auth/userStatus'] === 'clinic_active') {
    return false;
  }
}

// Must be logged in as a new clinic
const clinicNew = async () => {
  const authResult = await auth();
  if (authResult) {
    return authResult;
  } else if (!['clinic_new', 'clinic_pending'].includes(store.getters['auth/userStatus'])) {
    return '/';
  }
};

// Must be a super admin
const adminAuth = async () => {
  store.dispatch('showNavbar', true)
  let authResult = await auth();
  if (authResult) {
    return authResult;
  } else if (!store.getters['auth/isSuperAdmin']) {
    return false;
  }
}

const routes = [
  { path: '/calendar', name: "calendar", beforeEnter: clinicAuth, component: () => import('@/pages/CalendarPage.vue') },
  { path: '/', name: "home", beforeEnter: checkAuth, component: () => import('@/pages/LandingPage.vue') },
  { path: '/signup', name: "signup", component: () => import('@/pages/auth/SignupPage.vue') },
  { path: '/admin', name: "admin", beforeEnter: adminAuth, component: () => import('@/pages/admin/ApproveClinics.vue') },
  { path: '/welcome', name: "onboardclinic", beforeEnter: clinicNew, component: () => import('@/pages/auth/OnboardClinic.vue') },
  { path: '/settings', name: "settings", beforeEnter: clinicAuth, component: () => import('@/pages/SettingsPage.vue') },
  { path: '/logout', name: "logout", component: () => import('@/pages/auth/LogoutPage.vue') },
  { path: '/login', name: "login", beforeEnter: checkAuth, component: () => import('@/pages/auth/LoginPage.vue') },
  { path: '/password/forgot', name: "passwordforgot", component: () => import('@/pages/auth/PasswordForgotPage.vue') },
  { path: '/password/reset', name: "passwordreset", component: () => import('@/pages/auth/PasswordResetPage.vue') },

  // testing
  { path: '/upload', name: "upload", component: () => import('@/components/imageHandling/testing/FileUpload.vue') },
  { path: '/cropper', name: "cropper", component: () => import('@/components/imageHandling/ImageSelect.vue') },
  // { path: '/uploadandcrop', name: "uploadandcrop", component: () => import('@/components/imageHandling/ImageSelect.vue') },
  { path: '/pic', name: "pic", component: () => import('@/components/testing/CameraTest.vue') },
  { path: '/newtreatment', name: "newtreatment", component: () => import('@/components/testing/NewTreatment.vue') },
  { path: '/treatment/:id', name: "treatment", component: () => import('@/components/testing/GetTreatment.vue') },
  { path: '/treatments/', name: "treatments", component: () => import('@/components/testing/GetTreatments.vue') },
  { path: '/search/', name: "search", component: () => import('@/components/testing/SearchClinics.vue') },
  { path: '/createreview/', name: "createreview", beforeEnter: clinicAuth, component: () => import('@/pages/CreateReview.vue') },
  { path: '/requestreview/', name: "requestreview", beforeEnter: clinicAuth, component: () => import('@/pages/RequestReview.vue') },
  { path: '/uploadphotos/', name: "uploadphotos", beforeEnter: clinicAuth, component: () => import('@/pages/UploadPhotos.vue') },

  // dynamic routes
  { path: '/:slug', name: "clinic", component: () => import('@/pages/ClinicProfile.vue') },
  { path: '/:slug/book', name: "book", props: { fullscreen: true }, component: () => import('@/components/appointments/BookingFlow') },

  // forward '/:slug/reviews' to '/:slug/profile?p=reviews'
  { path: '/:slug/reviews', redirect: to => ({ name: 'clinic', params: { slug: to.params.slug }, query: { p: 'reviews' } }) },
  { path: '/:slug/photos', redirect: to => ({ name: 'clinic', params: { slug: to.params.slug }, query: { p: 'photos' } }) },
  { path: '/:slug/clinic', redirect: to => ({ name: 'clinic', params: { slug: to.params.slug }, query: { p: 'clinic' } }) },

  // catch all for '/:slug/*'
  { path: '/:slug/:catchAll(.*)', redirect: to => ({ name: 'clinic', params: { slug: to.params.slug } }) },

]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});


router.beforeEach(globalConfig);
router.afterEach(() => {
  store.dispatch('loading', false)
  // if (typeof window.gtag === 'function') {
  //   window.gtag('config', 'G-Q08TS5GRHX', { 'page_path': to.path });
  // }

})


// Configure axios
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(response => {
  return response;
}, error => {
  throw error.response.data.message || error;
})

app.use(router)

// use preloader animation

app.component('LoaderAni', LoaderAni);

import locale from '@/plugins/locale'
app.use(locale)
import forms from '@/plugins/forms'
app.use(forms)

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
let options = {
  defaultCountry: store.getters.country,
  preferredCountries: ["DK", "CA"]
}
app.use(VueTelInput, options);

// use custom confirm dialog

import confirmDialogPlugin from '@/plugins/confirm';
app.use(confirmDialogPlugin);

// use i18n
import i18n from './i18n';
app.use(i18n)


// use dayjs
import dayjsPlugin from './plugins/dayjs';
app.use(dayjsPlugin);

// use gtag
import VueGtag from "vue-gtag";
app.use(VueGtag, {
  config: { id: "G-Q08TS5GRHX" },
  params: {
    anonymize_ip: true
  }
})

app.mount('#app')



