export default {
  namespaced: true,
  state() {
    return {
      activeResource: null,
      view: null
    };
  },
  mutations: {
    setActiveResource(state, resource) {
      state.activeResource = resource;
    },
    setView(state, val) {
      state.view = val;
    }
  },
  actions: {
    setActiveResource(context, resource) {
      context.commit('setActiveResource', resource);
    },
    async setView(context, val) {
      context.commit('setView', val);
    },
  },

  getters: {
    activeResource: state => state.activeResource,
    view: state => state.view,
  }
}
