export default {
  state() {
    return {
      counter: 0,
      isLoggedIn: false
    };
  },
  mutations: {
    setState(state, payload) {
      state.isOpen = payload.val
    }
  },
  actions: {
    openMenu(context) {
      context.commit('setState', { val: true });
    },
    closeMenu(context) {
      context.commit('setState', { val: false });
    },
    toggleMenu(context) {
      context.commit('setState', { val: !this.getters.isOpen })
    }
  },
  getters: {
    isOpen(state) {
      return state.isOpen;
    },
  }
}