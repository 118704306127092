/**
 * Module dedicated to the data required to book an appointment (from either the clinic or customer side)
 */
export default {
  namespaced: true,
  state() {
    return {
      // Clinic for whom the booking is being made
      clinic: null,
      selectedDate: null,
      // Data to be submitted for the appointment booking
      bookingData: {
      }
    };
  },
  mutations: {
    setClinic(state, clinic) {
      state.clinic = clinic;
    },
    setSelectedDate(state, date) {
      state.selectedDate = date;
    },
    resetBookingData(state) {
      state.bookingData = {};
    },
    updateBookingData(state, object) {
      state.bookingData = Object.assign({}, state.bookingData, object);
    },
  },
  actions: {
    initBooking({ commit }, payload) {
      const { clinic, date } = payload;
      commit('setClinic', clinic);
      commit('setSelectedDate', date);
      commit('resetBookingData');
      commit('updateBookingData', { clinicId: clinic._id });
    },
  },

  getters: {
    clinic(state) {
      return state.clinic;
    },
    bookingData(state) {
      return state.bookingData;
    },
    selectedDate(state) {
      return state.selectedDate;
    },
  }
}
