<template>
  <div class="navbar" :class="isPWA ? 'pwa' : ''">
    <InspiruIcon class="logo" :height="40" color="#03D0BF" />
    <div class="nav">
      <!-- <a href="/calendar" :class="{ icon: true, active: $route.name === 'calendar' }">
        <span class="material-symbols-outlined">calendar_today</span>
      </a> -->
      <router-link v-if="isLoggedIn" to="/calendar" :class="{ icon: true, active: $route.name === 'home' }"><span class="material-symbols-outlined">calendar_today</span></router-link>
      <router-link v-if="isLoggedIn" to="/settings" :class="{ icon: true, active: $route.name === 'settings' }"><span class="material-symbols-outlined">settings</span></router-link>
      <router-link v-if="isSuperAdmin" to="/admin" :class="{ icon: true, active: $route.name === 'admin' }"><span class="material-symbols-outlined">group</span></router-link>
      <div class="spacer">

      </div>
      <div id="navbar-resources" class="navbar-resources">
      </div>
      <LanguagePicker />
      <router-link v-if="isLoggedIn" to="/logout" :class="{ icon: true, active: $route.name === 'power' }">
        <span class="material-symbols-outlined">power_rounded</span>
      </router-link>
      <!-- <a href="#" @click="logout" :class="{ icon: true, active: $route.name === 'power' }">
        <span class="material-symbols-outlined">power_rounded</span>
      </a> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InspiruIcon from "@/components/assets/InspiruIcon.vue";
import LanguagePicker from "@/components/ui/LanguagePicker.vue";
export default {
  name: "MainNav",
  components: { 
    InspiruIcon, 
    LanguagePicker
   },
  data() {
    return {
    };
  },
  methods: {
    async logout() {
      this.$router.push({ path: '/logout' })
    },
    gotoSettings() {
      this.$router.push({ path: '/settings' })
    }
  },
  computed: {
    ...mapGetters('auth', [
      'isLoggedIn',
      'isSuperAdmin',
    ]),
    isPWA() {
      return this.$store.getters.isPWA || false
    },  
  },
};
</script>

<style lang="sass" scoped>
$transition-sidebar: 0

@import "/src/styles/variables.sass"
.navbar
  position: fixed
  display: flex
  flex-direction: column
  opacity: 1
  visibility: visible
  width: 80px
  height: 100%
  transition: $transition-sidebar
  box-shadow: rgba(0, 0, 0, 0.1) -10px 0 10px
  z-index: 2
  padding: 20px
  background-color: $darker
  height:100%
  gap:20px

  .nav
    flex-grow:1
    display: flex
    flex-direction: column
    gap: 20px
    overflow: hidden

    .spacer
      height: 40px
      flex-grow: 1

  .icon
    color: white
    font-weight: 700
    font-size: 30px
    color: $icon-dark
    width: 40px
    height: 40px
    text-decoration: none
    padding: 6px
    overflow: hidden

    &.active
      color: $icon-light

.navbar-resources
  display: flex
  gap:20px
  flex-direction: column
  align-items: center



  select
    color: #333
    font-size: inherit
    font-weight: inherit
    text-transform: uppercase
    padding: 2px 4px

@media only screen and (max-width: 767px)
  .navbar
    width: 100%
    height: 60px
    flex-direction: row
    align-items: center
    justify-content: space-evenly
    top: none
    bottom: 0px
    .logo
      display: none
    .nav
      flex-direction: row
      align-items: center
      justify-content: space-evenly
      .spacer
        display: none
    &-footer
      width:100%
      flex-direction: row
      align-items: center

    &.pwa
      height: 70px
      padding: 10px 20px
      align-items: flex-start

  .navbar-resources
    gap:10px
    flex-direction: row
    flex-grow:1
    align-items: center
    justify-content: space-evenly

</style>
