
// import store from '@/store/index.js'

export default {
  install(app) {
    // app.config.globalProperties.$formatFee = (fee, locale) => {

    //   // let locale = store.getters['auth/userClinic'].locale

    //   return locale ? new Intl.NumberFormat(locale.language,
    //     { style: 'currency', currency: locale.currency })
    //     .format(fee) : null
    // }
    app.config.globalProperties.$formatFee = (value, locale, currency) => {
      if (!currency) {
        currency = locale.currency
      }
      const format = new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value);
      switch (currency) {
        case 'CAD':
          return format.replace('CA$', '$');
        case 'DKK':
          console.log(format)
          // if format contains DKK, execute the next line
          if (format.includes('DKK')) {
            return format.replace('DKK', '') + ' kr.';
          } else {
            return format;
          }
        default:
          return format;
      }
    }
  }
}
