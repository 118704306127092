import { createI18n } from 'vue-i18n';
import en from './locales/en-US'
import da from './locales/da-DK'

const messages = {
  'en-US': en,
  'da-DK': da
};

let browserLocale = window.navigator.language || window.navigator.userLanguage


if (browserLocale.includes('en')) {
  browserLocale = 'en-US';
} else {
  browserLocale = 'da-DK';
}

const i18n = createI18n({
  locale: browserLocale, // Set the initial locale
  fallbackLocale: 'da-DK', // Set the fallback locale for when a translation is missing
  messages
});

export default i18n;