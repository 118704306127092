import axios from 'axios';
// import cookies from 'vue-cookies'
import store from '@/store/index.js'

// create a function
const normalizeClinicData = (clinic) => {
  if (clinic.rating) {
    clinic.rating = parseFloat(clinic.rating?.$numberDecimal)
  }
  return clinic
}
let inspiru = {

  // TREATMENT PHOTOS

  async uploadTreatmentPhotos(photosData) {
    return (await axios.post('/photos/photo', photosData)).data;
  },

  async getPhotosByClinicId(clinicId) {
    return (await axios.get(`/photos/photos/${clinicId}`)).data;
  },

  async editPhoto(photoId, updatedData) {
    return (await axios.put(`/photos/photo/${photoId}`, updatedData)).data;
  },

  async deletePhoto(photoId) {
    return axios.delete(`/photos/photo/${photoId}`);
  },
  // REVIEWS

  async createReview(reviewData) {
    return (await axios.post('/reviews/review', reviewData)).data;
  },
  async getReview(id) {
    return (await axios.get(`/reviews/review/${id}`)).data;
  },
  async getReviewsByClinicId(clinicId) {
    return (await axios.get(`/reviews/reviews/${clinicId}`)).data;
  },

  async editReview(reviewId, updatedData) {
    return (await axios.put(`/reviews/review/${reviewId}`, updatedData)).data;
  },

  async deleteReview(reviewId) {
    return axios.delete(`/reviews/review/${reviewId}`);
  },

  async submitReviewRequest(reviewRequestData) {
    return (await axios.post('/reviews/review-request', reviewRequestData)).data;
  },

  // USERS

  async getUsers() {
    return (await axios.get(`/users/get-users`)).data;
  },
  async getUser(id) {
    return (await axios.get(`/users/get-user?id=${id}`)).data;
  },
  async getActiveResource() {
    let id = store.getters['auth/activeResource']._id
    let resource = (await axios.get(`/users/get-user?id=${id}`)).data;
    store.commit('auth/setActiveResource', resource)
    return resource
  },
  async getUserByEmail(email) {
    return (await axios.get(`/users/get-user-byemail?email=${email}`)).data;
  },

  async updateUser(id, fields) {
    console.log('updateUser', id, fields)
    return (await axios.put('/users/update-user', {
      id: id,
      fields: fields
    })).data;
  },

  async deleteUser(id, clinicId) {
    // let token = cookies.get('os_token')
    axios.delete(`/users/delete-user?id=${id}`, {});
    console.log(clinicId)
  },

  async createInspiruClinic(fields) {
    return (await axios.post('/clinics/create-clinic', {
      fields: fields
    })).data;
  },

  async getClinicBySlug(slug) {
    let clinic = (await axios.get(`/clinics/get-clinic-byslug?slug=${slug}`)).data;
    clinic = normalizeClinicData(clinic)
    return clinic
  },

  async getFullClinicBySlug(slug) {
    let clinic = (await axios.get(`/clinics/get-full-clinic`, { params: { slug } })).data
    clinic = normalizeClinicData(clinic)
    return clinic
    // return (await axios.get(`/clinics/get-full-clinic`, { params: { slug } })).data
  },

  async getClinic(id) {
    let clinic = (await axios.get(`/clinics/get-clinic?id=${id}`)).data;
    clinic = normalizeClinicData(clinic)
    return clinic
  },

  async getFullClinic(id) {
    let clinic = (await axios.get(`/clinics/get-full-clinic?id=${id}`)).data
    clinic = normalizeClinicData(clinic)
    return clinic
  },

  async updateClinic(id, fields) {
    return (await axios.put('/clinics/update-clinic', {
      id: id,
      fields: fields
    })).data;
  },

  async updateClinics() {
    return axios.put('/clinics/update-clinics');
  },


  // TREATMENTS AND CATEGORIES

  async getTreatments() {
    return (await axios.get('/treatments/treatments')).data;
  },

  // SERVICES 

  async getService(id) {
    return (await axios.get(`/services/${id}`)).data;
  },
  async getServices(clinicId) {
    return (await axios.get(`/services?clinicId=${clinicId}`)).data;
  },
  async createService(fields) {
    return axios.post('/services', {
      fields: fields
    });
  },
  async deleteService(id) {
    return axios.delete(`/services/${id}`);
  },

  async updateService(id, fields) {
    return axios.put(`/services/${id}`, {
      fields: fields
    });
  },

  async createFakeServices(clinicId) {
    return (axios.post('/services/create-fake-services', { clinicId: clinicId })).data;
  },

  // AVAILABILITY

  async findAvailableTimeslots(params) {
    console.log('findAvailableTimeslots', params)
    // const params = { clinicId, serviceId, resourceId, startDate, endDate, duration };
    // return (await axios.get(`/availability`, { params })).data;
    let response = (await axios.get(`/availability`, { params })).data;
    console.log('findAvailableTimeslots response', response)
    return response.timeslots

  },

  // APPOINTMENTS

  async getAppointments(clinicId, resourceId) {
    const params = { clinicId, resourceId, limit: 50 };
    // should check for status = booked
    return (await axios.get('/appointments', { params })).data;
  },
  async getAppointment(id) {
    return (await axios.get(`/appointments/${id}`)).data;
  },
  async createAppointment(fields, silent) {
    return (await axios.post('/appointments', {
      fields: fields,
      silent: silent
    })).data;
  },
  async updateAppointment(id, fields, silent) {
    return axios.put(`/appointments/${id}`, {
      fields: fields,
      silent: silent
    });
  },
  async cancelAppointment(id, fields, silent) {
    return axios.patch(`/appointments/${id}/cancel`, {
      fields: fields,
      silent: silent
    });
  },
  async confirmAppointment(id, fields, silent) {
    return axios.patch(`/appointments/${id}/confirm`, {
      fields: fields,
      silent: silent
    });
  },
  async betaSubscribe(email) {
    return axios.post('/users/beta-subscribe', {
      email: email
    });
  }
}

export default inspiru