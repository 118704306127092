<template>
  <div class="language-picker">
    <div @click="showModal" class="language-picker__select">
      <div class="flag nav" >
        <img :src="flagImg" alt="Language flag">
      </div>
      <div v-if="label" class="ml2">{{ label }}</div>
    </div>
    <Transition name="fade">
      <div class="modal-overlay" v-show="isModalOpen" @click="hideModal"></div>
    </Transition>
    <Transition name="fade">
      <div class="modal" v-show="isModalOpen">
        <div class="title">
          Language
        </div>
        <div class="content">
          <label v-for="lang in languages" :key="lang.code">
            <input type="radio" name="language" @click="handleClick(lang.code)" v-model="$i18n.locale" :value="lang.code">
            <img class="flag ml2" :src="flagImage(lang.code)"><span>{{ $t(`languages.${lang.code}` ) }}</span>
          </label>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import cookies from 'vue-cookies';

export default {
  data() {
    return {
      isModalOpen: false,
      languages: [
        { code: 'en-US', name: 'English' },
        { code: 'da-DK', name: 'Dansk' },
        // { code: 'fr-FR', name: 'Français' },
      ]
    };
  },
  props: ['label'],
  computed: {
    flagImg() {
      return require(`@/i18n/flags/${this.$i18n.locale}.svg`);
    },
  },
  methods: {
    flagImage(locale) {
      return require(`@/i18n/flags/${locale}.svg`);
    },
    showModal() {
      this.isModalOpen = true;
    },
    hideModal() {
      this.isModalOpen = false;
    },
    handleClick(locale) {
      setTimeout(() => {
        cookies.set('lang', locale);
        this.hideModal();
      }, 500);
    },
  },
  beforeMount() {
    const langCookie = cookies.get('lang');
      if (langCookie) {
        this.$i18n.locale = langCookie;
      }
  },
};
</script>

<style lang="sass" scoped>
.language-picker
  display: inline-block
  position: relative
  cursor: pointer
  &__select
    display: flex
    position: relative
    align-items: center
    justify-content: center
    gap: 8px



.flag
  display: flex
  align-content: center
  justify-content: center
  border-radius: 50%
  overflow: hidden
  cursor: pointer
  height: 24px
  object-fit: cover


.modal-overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.2)
  z-index: 999

.modal
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  min-width: 180px
  max-width: 80%
  background-color: #fff

  border-radius: 12px
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)
  z-index: 1000
  overflow: hidden

.modal
  .title
    color: #aaa
    text-transform: uppercase
    //character spacing
    letter-spacing: .4em
    font-size: .7em
    font-weight: 400
    padding: 16px
    margin-top: 0
    background: #333
  
.content
  padding: 16px 0
  display: flex
  flex-direction: column
  gap: 0px

  label
    font-size: .9em 
    font-weight: 400
    display: flex
    align-items: center
    gap: 6px
    cursor: pointer
    padding: 10px 16px
    border-radius: 6px
    &:hover
      background: #fafafa
    &:active
      background: #eee  
    
  input
    margin: 0

</style>

