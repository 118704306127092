<template>
  <LoaderAniOverlay />
  <MainNav v-if="showNavbar"/>
  <Teleport v-if="showNavbar && resource" to="#navbar-resources">
      <activeResourceAvatars
        size="small"
      ></activeResourceAvatars>
    </Teleport>
  <div id="main" class="app">

    <router-view></router-view>
  </div>
</template>

<script>
import MainNav from "@/components/layout/MainNav.vue";
import LoaderAniOverlay from "./components/ui/LoaderAniOverlay.vue";
import activeResourceAvatars from "./components/resources/activeResourceAvatars.vue";
export default {
  components: {
    MainNav,
    LoaderAniOverlay, 
    activeResourceAvatars,
  },
  data() {
    return {
    };
  },
  computed: {
    isClinic() {
      return this.$store.getters['auth/userClinic']
    },
    resource() {
      return this.$store.getters['calendar/activeResource']
    },
    showNavbar() {
      return this.$store.getters.showNavbar || false
    },
    error() {
      return this.$store.getters['auth/error']
    },
  },
  watch: {
    error(msg) {
      if (msg) {
        this.$toast_error.show(msg)
      }
    },
  },
  name: "App",
  async mounted() {
    
  }
};
</script>

<style lang="sass">
@import "/src/styles/styles.sass"

</style>